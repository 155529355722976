<template>
  <div>
    <h4 class="mb-2">
      General Settings
    </h4>
    <settings-view
      :settings="settings"
      :form="form"
      :is-loading="isProcessing"
    />
    <hr>
    <b-button
      variant="primary"
      class="float-right"
      :disabled="isProcessing"
      @click="updateUserGeneralSettings"
    >
      Save
    </b-button>
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BButton } from 'bootstrap-vue'
import SettingsView from '@/views/common/components/SettingsView.vue'

export default {
  components: {
    BButton,
    SettingsView,
  },
  props: {
    selectedUser: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        disableScreencast: false,
        idleTime: 15,
      },
      settings: {
        disableScreencast: {
          icon: 'MonitorIcon',
          name: 'disableScreencast',
          title: 'Enable Screen Capture',
          description: 'Capture screenshots periodically and send them to server.',
          inputType: 'switch',
          reverse: true,
        },
        idleTime: {
          icon: 'ClockIcon',
          name: 'idleTime',
          title: 'Idle Time Setting',
          description: 'Stop tracking time after being idle for',
          inputType: 'number',
          append: 'mins',
        },
      },
      isProcessing: false,
    }
  },
  mounted() {
    this.getUserGeneralSettings()
  },
  methods: {
    getUserGeneralSettings() {
      this.isProcessing = true
      useApollo.users.getUserGeneralSettings({
        userUid: this.selectedUser,
        projectUid: this.$store.state.project.selectedProject,
      }).then(response => {
        const data = response.data.project.users.data[0]?.generalSettings?.data[0]?.information
        this.form = {
          disableScreencast: !!(data && data.disableScreencast),
          enableCloudServices: false,
          idleTime: data && data.idleTime ? data.idleTime : 15,
        }
      }).finally(() => { this.isProcessing = false })
    },
    updateUserGeneralSettings() {
      useApollo.users.updateUserGeneralSettings({
        userUid: this.selectedUser,
        projectUid: this.$store.state.project.selectedProject,
        input: {
          ...this.form,
          idleTime: Number(this.form.idleTime),
        },
      }).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.updateUserProjectGeneralSettings.message,
          },
        })
      })
    },
  },
}
</script>
