<template>
  <div>
    <h4 class="mb-2">
      User Status
    </h4>

    <settings-view
      :settings="settings"
      :form="form"
      :is-loading="isProcessing"
    />
    <hr>
    <b-button
      variant="primary"
      class="float-right"
      :disabled="isProcessing"
      @click="changeUserStatusInProject"
    >
      Save
    </b-button>
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BButton } from 'bootstrap-vue'
import SettingsView from '@/views/common/components/SettingsView.vue'

export default {
  components: {
    BButton,
    SettingsView,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        enableUser: true,
      },
      settings: {
        disableUserStatus: {
          icon: 'UserCheckIcon',
          name: 'enableUser',
          title: 'Activate User Status',
          description: `Activate ${this.selectedUser.name}'s status in project - ${this.$store.state.project.project.name}`,
          inputType: 'switch',
        },
      },
      isProcessing: false,
    }
  },
  mounted() {
    if (this.selectedUser) {
      this.form.enableUser = this.selectedUser.projectRoles.data[0].deactivatedAt === null
    }
  },
  methods: {
    changeUserStatusInProject() {
      this.isProcessing = true
      useApollo.users.changeUserStatusInProject({
        userUid: this.selectedUser.uuid,
        projectUid: this.$store.state.project.selectedProject,
        enable: this.form.enableUser,
      }).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.changeUserStatusInProject.message,
          },
        })
        this.$emit('refetch-users')
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>
